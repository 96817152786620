.arrow {
  position: absolute;
  top: 50%;
  z-index: 10;
}

.right {
  right: 0;
  z-index: 10;
}

.left {
  left: 0;
  z-index: 10;
}
