html {
  margin: 0px;
  width: fit-content;
}
.app {
  margin: 0px;
}
@font-face {
  font-family: "Peshang";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./Peshang_Des_5_Bold.woff") format("truetype");
    font-weight: 500;
}

.ku,
.ar {
  font-family: "Peshang";
}
