@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Peshang"; /*Can be any text*/
  src: local("AirbnbCerealLight"), url("./Speda-Bold.woff") format("truetype");
}

.ku,
.ar {
  font-family: "Peshang";
  direction: rtl;
}
@keyframes gradientAnimation1 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes gradientAnimation2 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-gradient1 {
  animation: gradientAnimation1 10s linear infinite;
}

.animate-gradient2 {
  animation: gradientAnimation2 10s linear infinite;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c8e4e8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1e6870;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0f4049;
}

@layer components {
  .btn-increase {
    @apply inline-flex items-center justify-center w-6 p-1 font-semibold transition-all border border-transparent rounded-md bg-secondary-100 text-secondary-500 hover:text-white hover:bg-secondary-500 focus:outline-none focus:ring-2 ring-offset-white focus:ring-secondary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
  }
  .btn-decrease {
    @apply inline-flex items-center justify-center w-6 p-1 font-semibold transition-all border border-transparent rounded-md bg-rose-100 text-rose-500 hover:text-white hover:bg-rose-500 focus:outline-none focus:ring-2 ring-offset-white focus:ring-rose-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
  }
}
