input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
ul {
  list-style-type: disc;
}
ol {
  list-style-type: decimal;
}
