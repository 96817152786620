#chatCon {
    width: 20%;
    position: fixed;
    bottom: 3%;
    right: 1%;
  }
  
  #chatCon .chat-box {
    /* display: none; */
    width: 100%;
    height: 500px;
    border-radius: 25px;
    background-color: #eee;
  }
  
  #chatCon .chat-box .header {
    background-color: #12B980;
    padding: 15px;
    border-radius: 20px 20px 0 0;
    color: #fff;
    font-size: 20px;
  }
  
  #chatCon .chat-box .msg-area {
    overflow: hidden;
    height: 370px;
    padding: 15px;
  }
  
  #chatCon .chat-box .msg-area .left span {
    display: inline-block;
    font-size: 17.5px;
    border-radius: 15px;
    padding: 15px;
    background-color: #ddd;
  }
  
  #chatCon .chat-box .msg-area .right {
    text-align: right;
  }
  
  #chatCon .chat-box .msg-area .right span {
    display: inline-block;
    font-size: 17.5px;
    border-radius: 15px;
    padding: 15px;
    background-color: #ddd;
  }
  
  #chatCon .chat-box .footer {
    padding: 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  
  #chatCon .chat-box .footer input {
    border: 1px solid #fff;
    padding: 10px;
    width: 80%;
    border-radius: 15px;
  }
  
  #chatCon .chat-box .footer input {
    outline: none;
  }
  
  #chatCon .chat-box .footer button {
    border: none;
    font-size: 22.5px;
    color: #12B980;
    cursor: pointer;
  }
  
  #chatCon .pop {
    width: 100%;
    height: 25%;
    cursor: pointer;
  }
  
  #chatCon .pop p {
    text-align: right;
  }
  
  #chatCon .pop img {
    border-radius: 50%;
    width: 25%;
  }
  /*# sourceMappingURL=auto.css.map */