/*css with 2 animation ---> shimmer, shadow   */
.preloader{
    width: 100%;
    height: 100vh;
    padding: 0px;
    margin: 0px;
	  display: flex;
    flex-flow: row nowrap;
	  justify-content: center;
    align-items: center;
    background: #f7f7f7;
}
.box_section{
    width: 40%;
    height: 40%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    background: white;
    border-radius: 20px;
    animation:1s ease-in-out alternate infinite shadows;
}
.site_logo{
    width:400px;
}
.img_filter{
    width: 40%;
    height: 40%;
    opacity:0.45 ;
    position: absolute;
    -webkit-animation-duration: 0.9s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    background: #f6f7f9;
    background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
    background-repeat: no-repeat;
}
@keyframes placeHolderShimmer{
  
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
    }
@keyframes shadows{
    0%{
        box-shadow:none
    }
    100%{
        box-shadow: 9px 16px 8px rgb(227, 227, 227);
    }
}