.carousel {
  position: relative;
  width: 23rem;
  height: 23rem;
  perspective: 500px;
  transform-style: preserve-3d;
}

.card-container {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(calc(var(--offset) * 50deg))
    scaleY(calc(1 + var(--abs-offset) * -0.4))
    translateZ(calc(var(--abs-offset) * -30rem))
    translateX(calc(var(--direction) * -5rem));
  filter: blur(calc(var(--abs-offset) * 1rem));
  transition: all 0.3s ease-out;
}

.card {
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: hsl(178deg, 71%, calc(100% - var(--abs-offset) * 43%));
  border-radius: 1rem;
  color: #9ca3af;
  text-align: justify;
  transition: all 0.3s ease-out;
}
.card h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 0 0 0.7em;
  color: #1f2937;
}
.card p,
.card h2 {
  transition: all 0.3s ease-out;
  opacity: var(--active);
}

.nav {
  color: white;
  font-size: 5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  z-index: 2;
  cursor: pointer;
  user-select: none;
  background: unset;
  border: unset;
}
.nav.left {
  transform: translateX(-100%) translatey(-50%);
}
.nav.right {
  right: 0;
  transform: translateX(100%) translatey(-50%);
}

@media screen and (max-width: 768px) {
  .carousel {
    width: 14rem;
    overflow-wrap: normal;
    height: 23rem;
  }
}
.card-container {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(calc(var(--offset) * 30deg))
    scaleY(calc(1 + var(--abs-offset) * -0.3))
    translateZ(calc(var(--abs-offset) * -30rem))
    translateX(calc(var(--direction) * 5px));
  filter: blur(calc(var(--abs-offset) * 5px));
  transition: all 0.3s ease-out;
}
@media screen and (min-width: 992px) {
  .carousel {
    width: 22rem;
    overflow-x: 3px;
    height: 23rem;
  }
  .card-container {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotateY(calc(var(--offset) * 50deg))
      scaleY(calc(1 + var(--abs-offset) * -0.4))
      translateZ(calc(var(--abs-offset) * -30rem))
      translateX(calc(var(--direction) * -5rem));
    filter: blur(calc(var(--abs-offset) * 1rem));
    transition: all 0.3s ease-out;
  }
}
