@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
/* 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

body {
  font-family: "Open Sans", sans-serif;
  background-color: #f5f6fa;
}

img {
  width: 100%;
  display: block;
} */

ul {
  list-style: none;
}

/* a {
  text-decoration: none;
  display: inline-block;
  width: 100%;
} */

.ltr {
  direction: ltr !important;
}
.main {
  padding: 25px;
}
.section-padding {
  padding: 1rem;
}
.section-border-radius {
  border-radius: 10px;
}
.section-header {
  margin-bottom: 25px;
}
.clear-float {
  clear: both;
}

/* ::::::::::::::::::::::::::::: Nav */
.main .nav {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 70px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 10px 0;
  background-color: #2d3037;

  transition: transform 0.2s linear;
}

.main .burger-menu {
  position: absolute;
  color: #fff;
  font-size: 18px;
  left: 100%;
  background-color: #2d3037;
  height: 35px;
  width: 40px;
  text-align: center;
  line-height: 35px;
  border-radius: 0 5px 5px 0;
  display: none;
}

.main .nav .navbar-logo,
.main .nav .navbar-user {
  max-width: 40px;
  display: block;
  margin: 0 auto;
}

.main .nav .navbar-logo {
  margin-bottom: 10px;
}

.main .nav .navbar-user {
  margin-top: 10px;
}

.main .nav .navbar-logo img,
.main .nav .navbar-user img {
  border-radius: 50px;
}

.main .navigation .link {
  padding: 10px 8px;
  text-align: center;
  font-size: 16px;
  color: #999;
  position: relative;
}

.main .navigation .link:hover,
.main .navigation .link.active {
  color: #fff;
}

.main .navigation .link:before,
.main .navigation .link.active:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0px;
  background-color: #338eda;
  border-radius: 0 5px 5px 0;
  transition: width 0.2s linear;
}

.main .navigation .link:hover:before,
.main .navigation .link.active:before {
  width: 5px;
}

/* ::::::::::::::::::::::::::::: Content */
.content {
  padding-left: 70px;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px;
  margin: 0 auto;
}

/* ::::::::::::::::::::::::::::: Head */
.content .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.content .head form {
  display: flex;
  align-items: center;
  padding: 0.2rem 0.6rem;
  background-color: #eaf5ff;
  border-radius: 5px;
}

.content .head .input-search {
  padding: 0.5rem 0.6rem;
  width: 100%;
  min-width: 300px;
  border: none;
  outline: none;
  background-color: transparent;
}

.content .head .links {
  display: flex;
  align-items: center;
}

.content .head .links li {
  margin-left: 8px;
}

.content .head .links a {
  height: 25px;
  width: 25px;
  line-height: 25px;
  text-align: center;
  color: #d1d1d1;
}

.content .head .links a.profile {
  line-height: 30px;
  width: auto;
  display: flex;
  align-items: center;
}

.content .head .links a.profile img {
  max-width: 20px;
  display: inline;
  border-radius: 50%;
  margin-right: 5px;
}

.content .head .links a.profile .fas {
  font-size: 9px;
}

/* ::::::::::::::::::::::::::::: Discover */
.discover {
  margin: 2rem 0;
}
.discover .discover-videos {
  background-color: #fff;
}
.discover .list-videos {
  display: flex;
  justify-content: space-between;
}
.discover .list-videos .video {
  width: 31%;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
.discover .list-videos .video .details {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #d7d2cc00 0%, #304352ee 100%);
  color: #fff;
  cursor: pointer;
}
.discover .list-videos .video .details .play {
  height: 70px;
  width: 70px;
  display: inline-block;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 70px;
  color: lightsalmon;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.discover .list-videos .video .details .time {
  display: inline-block;
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-size: 12px;
  padding: 2px 6px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 3px;
}

/* ::::::::::::::::::::::::::::: Analytics */
.analytics {
  margin: 2rem 0;
}
.analytics .list-analytics {
  display: flex;
  justify-content: space-between;
}
.analytics .list-analytics .analytic {
  background-color: #fff;
  width: 23%;
  border-radius: 10px;
  text-align: center;
  padding: 2rem 1rem;
  transition: box-shadow 0.2s ease-in;
}
.analytics .list-analytics .analytic:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.analytics .list-analytics .analytic .icon {
  height: 60px;
  width: 60px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
}

.analytics .list-analytics .a .icon {
  background-color: #e5f3ff;
  color: #0277ed;
}
.analytics .list-analytics .b .icon {
  background-color: #f4f1ff;
  color: #8964f9;
}
.analytics .list-analytics .c .icon {
  background-color: #fff9e5;
  color: #f4b20a;
}
.analytics .list-analytics .d .icon {
  background-color: #ebfaea;
  color: #46bd4c;
}

.analytics .list-analytics .analytic .num {
  font-size: 40px;
  margin: 25px 0 5px 0;
  transform: scaleY(1.1);
}
.analytics .list-analytics .analytic .desc {
  font-size: 12px;
  color: #7f8488;
  margin-bottom: 35px;
}

.analytics .list-analytics .analytic .up {
  color: #1ebe2c;
}
.analytics .list-analytics .analytic .down {
  color: #f62421;
}

.analytics .list-analytics .analytic .percent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.analytics .list-analytics .analytic .percent .value {
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
}

/* ::::::::::::::::::::::::::::: Message */
.message {
  background-color: #303346;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 2rem 2rem;
  overflow: hidden;
  margin: 2rem 0;
}
.message::before,
.message::after {
  content: "";
  position: absolute;
  z-index: 1;
}
.message::before {
  height: 40vw;
  width: 40vw;
  left: -20vw;
  bottom: 10px;
  border-radius: 50%;
  background-color: #109488;
}
.message::after {
  height: 25vw;
  width: 25vw;
  right: -5vw;
  bottom: -22vw;
  border-radius: 50%;
  background-color: #252837;
  box-shadow: -10px -50px 0px 15px rgba(255, 255, 255, 0.2);
}
.message * {
  position: relative;
  z-index: 2;
}
.message .text {
  color: #fff;
  max-width: 80%;
  line-height: 1.6;
  font-weight: 600;
}
.message .btn-discover {
  outline: none;
  border: 1px solid #109488;
  padding: 0.3rem 0.6rem;
  min-width: 110px;
  font-family: "Open Sans", sans-serif;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  background-color: #109488;
  cursor: pointer;
  transition: all 0.3s linear;
}
.message .btn-discover:hover {
  background-color: transparent;
}
.message .icon-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 10px;
  color: #fff;
  cursor: pointer;
}

/* ::::::::::::::::::::::::::::: Events + Calendar */
.events-calendar {
  display: flex;
  justify-content: space-between;
}

/* .events-calendar .events {
    width: 64%; 
} */
.events-calendar .events .list-events {
  display: flex;
}
.events-calendar .events .list-events .event {
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem 1.5rem 5rem 1.5rem;
  width: 49%;
  position: relative;
  transition: box-shadow 0.2s linear;
}
.events-calendar .events .list-events .event:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.events-calendar .events .list-events .event .title {
  max-width: 400px;
  border-radius: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.4rem 0.6rem;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 2.5rem;
}
.events-calendar .events .list-events .event .title.green {
  background-color: #f4fcef;
  color: #abe588;
}
.events-calendar .events .list-events .event .title.orange {
  background-color: #fff9ef;
  color: #ffd694;
}

.events-calendar .events .list-events .event .sub-title {
  display: flex;
  align-items: center;
  color: #4a4b54;
}
.events-calendar .events .list-events .event .sub-title i {
  font-size: 18px;
}
.events-calendar .events .list-events .event .sub-title h3 {
  font-size: 14px;
  margin-left: 5px;
}

.events-calendar .events .list-events .event .description {
  font-size: 13px;
  color: #a2a3a7;
  margin: 15px 0;
}
.events-calendar .events .list-events .event .person {
  display: inline-flex;
  align-items: center;
  border: 1px solid #f0f0f0;
  padding: 5px 15px 5px 5px;
  border-radius: 50px;
}
.events-calendar .events .list-events .event .person img {
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}
.events-calendar .events .list-events .event .person span {
  margin-left: 10px;
  font-size: 12px;
  color: #707078;
  display: inline-block;
  vertical-align: middle;
}

.events-calendar .events .list-events .event .time {
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
  font-size: 12px;
}
.events-calendar .events .list-events .event .time .icon,
.events .list-events .event .time .text {
  color: #ccd4de;
}
.events-calendar .events .list-events .event .time .date {
  color: #a5bdff;
  font-weight: 600;
}

.events-calendar .calendar {
  width: 34%;
  display: flex;
  flex-direction: column;
}
.events-calendar .calendar .calendar-details {
  background-color: #fff;
  flex-grow: 1;
}
.events-calendar .calendar .calendar-details .date {
  float: left;
  font-size: 14px;
  color: #4c4d56;
}
.events-calendar .calendar .calendar-details .navigation {
  float: right;
  font-size: 12px;
  color: #bac5d3;
}
.events-calendar .calendar .calendar-date {
  margin-top: 2rem;
  width: 100%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.events-calendar .calendar .calendar-date thead th {
  padding-bottom: 1rem;
  font-size: 13px;
  color: #bac5d3;
}
.events-calendar .calendar .calendar-date tbody td {
  font-size: 12px;
  color: #83838a;
  padding: 0 0.5rem;
  font-weight: 600;
  height: 30px;
}
.events-calendar .calendar .calendar-date tbody td.lm {
  color: #ccd4de;
}
.events-calendar .calendar .calendar-date tbody td.act {
  color: #fff;
}
.events-calendar .calendar .calendar-date tbody td.act span {
  height: 30px;
  width: 30px;
  line-height: 30px;
  display: inline-block;
  background-color: #4c7dfe;
  border-radius: 15px;
  color: #fff;
}

/* ::::::::::::::::::::::::::::: Apps + Features */
.apps-features {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
}

.apps-features .apps {
  width: 64%;
}
.apps-features .list-apps {
  background-color: #fff;
}
.apps-features .list-apps .app {
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f6f9;
  color: #686f7d;
  transition: all 0.3s ease-out;
}
.apps-features .list-apps .app:hover {
  background-color: #95d0d5;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.apps-features .list-apps .app:not(:first-child) {
  margin-top: 1rem;
}

.apps-features .list-apps .app .desc {
  width: 30%;
}
.apps-features .list-apps .app .desc,
.apps-features .list-apps .app .type {
  display: flex;
  align-items: center;
}

.apps-features .list-apps .app .type,
.apps-features .list-apps .app .time {
  width: 25%;
}

.apps-features .list-apps .app .desc .icon {
  height: 35px;
  width: 35px;
  display: inline-block;
  text-align: center;
  line-height: 35px;
  background-color: #fff;
  border-radius: 5px;
}
.apps-features .list-apps .app .desc .fa-facebook {
  color: #1877f2;
}
.apps-features .list-apps .app .desc .fa-twitter {
  color: #1da1f2;
}
.apps-features .list-apps .app .desc .fa-whatsapp {
  color: #25d366;
}

.apps-features .list-apps .app .desc h3,
.apps-features .list-apps .app .type h3,
.apps-features .list-apps .app .time {
  font-weight: 600;
  font-size: 13px;
  margin-left: 8px;
}

.apps-features .list-apps .app .btn-cont {
  text-align: right;
}
.apps-features .list-apps .app .btn-view {
  font-size: 12px;
  border: 1px solid #888;
  display: inline;
  padding: 0.2rem 0.6rem;
  margin-right: auto;
  border-radius: 3px;
  color: #333;
}

.apps-features .features {
  width: 34%;
  display: flex;
  flex-direction: column;
}
.apps-features .features .feature {
  background-color: #f7f7f7;
  flex-grow: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;
  color: #46284f;
}
.apps-features .features .feature > * {
  position: relative;
  z-index: 2;
}
.apps-features .features .price-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2rem;
}
.apps-features .features .price-container h2 {
  font-size: 3rem;
}
.apps-features .features .text {
  width: 75%;
  margin-top: 1rem;
  font-size: 14px;
}
.apps-features .features .img {
  width: 180px;
  position: absolute;
  right: -3%;
  bottom: -3%;
  z-index: 1;
}

/* ::::::::::::::::::::::::::::: Footer */
.footer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer .links {
  display: flex;
  margin-bottom: 25px;
}
.footer .links li:not(:first-child):not(:last-child) {
  margin: 0 10px;
}
.footer .links a {
  padding: 0.3rem 0.8rem;
  min-width: 110px;
  text-align: center;
  color: lightblue;
}

.footer .links a:hover {
  transition: all 0.2s linear;
  color: #555;
}
.footer .copyright {
  font-size: 13.5px;
  margin-bottom: 8px;
  color: #222;
}
.footer .copyright span {
  color: lightsalmon;
  font-weight: 600;
  font-size: 14.5px;
}

/*------------------------*/
@media screen and (max-width: 960px) {
  .discover .list-videos,
  .analytics .list-analytics,
  .events-calendar,
  .apps-features {
    flex-wrap: wrap;
  }
  .discover .list-videos .video {
    width: 48%;
  }
  .discover .list-videos .video:first-child {
    width: 100%;
    margin-bottom: 15px;
  }

  .analytics .list-analytics .analytic {
    width: 48%;
  }
  .analytics .list-analytics .analytic:nth-child(1),
  .analytics .list-analytics .analytic:nth-child(2) {
    margin-bottom: 20px;
  }

  .events-calendar .events,
  .events-calendar .calendar,
  .apps-features .apps,
  .apps-features .features {
    width: 100%;
  }
  .events-calendar .calendar,
  .apps-features .features {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 560px) {
  .main {
    padding-left: 5px;
    padding-right: 5px;
  }
  .content .head {
    flex-direction: column;
  }
  .content .head .links {
    order: 1;
    align-self: flex-end;
  }
  .content .head form {
    order: 2;
    align-self: flex-start;
    width: 100%;
    margin-top: 20px;
  }

  .discover .list-videos,
  .analytics .list-analytics,
  .message,
  .events-calendar .events .list-events,
  .events-calendar,
  .apps-features {
    display: block;
  }

  .discover .list-videos .video,
  .analytics .list-analytics .analytic,
  .events-calendar .events .list-events .event {
    width: 100%;
  }
  .discover .list-videos .video:not(:last-child),
  .analytics .list-analytics .analytic:not(:last-child),
  .events-calendar .events .list-events .event:not(:last-child) {
    margin-bottom: 15px;
  }

  .message {
    text-align: center;
  }
  .message .text {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 700px) {
  .main .nav {
    transform: translateX(-100%);
  }

  .main .nav:hover {
    transform: translateX(0);
  }

  .main .nav .burger-menu {
    display: block;
  }

  .content {
    padding-left: 0px;
  }
}
